import create from "zustand";
import type { AlertProps } from "@merit/frontend-components";

export type AlertState = {
  readonly alertList: readonly AlertProps[];
  readonly setAlert: (alert: AlertProps) => void;
  readonly deleteAlert: (id: string) => void;
};

const DURATION = 5000;

export const useAlertStore = create<AlertState>(set => ({
  alertList: [],
  deleteAlert: (id: string) => {
    set(state => ({
      alertList: state.alertList.filter(alert => alert.id !== id),
    }));
  },
  setAlert: (alert: AlertProps) => {
    set(state => {
      // Auto hides the alert based on duration
      setTimeout(() => {
        state.deleteAlert(alert.id);
      }, DURATION);

      return { alertList: [...state.alertList, alert] };
    });
  },
}));
