import { Body, Heading, MeritLogo, useTheme } from "@merit/frontend-components";
import { Image, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import { VerticalSpacer } from "../../components/Spacer";
import React from "react";

export const ErrorScreen = () => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    centerContent: { alignItems: "center", justifyContent: "center" },
  });

  const reload = () => {
    window.location.reload();
  };

  return (
    <>
      <View style={[styles.centerContent, { padding: 48 }]}>
        <MeritLogo />
      </View>
      <View style={[styles.centerContent, { flex: 1 }]}>
        <Image source={Images.error500} style={{ height: 254, width: 280 }} />

        <VerticalSpacer size={61} />

        <Heading level="1" style={{ fontSize: 42 }}>
          Something went wrong
        </Heading>
        <VerticalSpacer size={theme.spacing.xxl} />
        <Body center color={theme.colors.action.default} onPress={reload}>
          Please reload to try again
        </Body>
      </View>
    </>
  );
};
