/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateOrgs200ResponseOrgDetailsInner } from './CreateOrgs200ResponseOrgDetailsInner';
import {
    CreateOrgs200ResponseOrgDetailsInnerFromJSON,
    CreateOrgs200ResponseOrgDetailsInnerFromJSONTyped,
    CreateOrgs200ResponseOrgDetailsInnerToJSON,
} from './CreateOrgs200ResponseOrgDetailsInner';

/**
 * 
 * @export
 * @interface CreateOrgs200Response
 */
export interface CreateOrgs200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200Response
     */
    filenameWithOrgId?: string;
    /**
     * 
     * @type {Array<CreateOrgs200ResponseOrgDetailsInner>}
     * @memberof CreateOrgs200Response
     */
    orgDetails: Array<CreateOrgs200ResponseOrgDetailsInner> | null;
}

/**
 * Check if a given object implements the CreateOrgs200Response interface.
 */
export function instanceOfCreateOrgs200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgDetails" in value;

    return isInstance;
}

export function CreateOrgs200ResponseFromJSON(json: any): CreateOrgs200Response {
    return CreateOrgs200ResponseFromJSONTyped(json, false);
}

export function CreateOrgs200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrgs200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filenameWithOrgId': !exists(json, 'filenameWithOrgId') ? undefined : json['filenameWithOrgId'],
        'orgDetails': (json['orgDetails'] === null ? null : (json['orgDetails'] as Array<any>).map(CreateOrgs200ResponseOrgDetailsInnerFromJSON)),
    };
}

export function CreateOrgs200ResponseToJSON(value?: CreateOrgs200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filenameWithOrgId': value.filenameWithOrgId,
        'orgDetails': (value.orgDetails === null ? null : (value.orgDetails as Array<any>).map(CreateOrgs200ResponseOrgDetailsInnerToJSON)),
    };
}

