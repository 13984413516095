/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetCSVUploadLink200Response
 */
export interface GetCSVUploadLink200Response {
    /**
     * 
     * @type {string}
     * @memberof GetCSVUploadLink200Response
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof GetCSVUploadLink200Response
     */
    filename: string;
}

/**
 * Check if a given object implements the GetCSVUploadLink200Response interface.
 */
export function instanceOfGetCSVUploadLink200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "filename" in value;

    return isInstance;
}

export function GetCSVUploadLink200ResponseFromJSON(json: any): GetCSVUploadLink200Response {
    return GetCSVUploadLink200ResponseFromJSONTyped(json, false);
}

export function GetCSVUploadLink200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCSVUploadLink200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': json['link'],
        'filename': json['filename'],
    };
}

export function GetCSVUploadLink200ResponseToJSON(value?: GetCSVUploadLink200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
        'filename': value.filename,
    };
}

