import { Button, MeritLogo, useTheme } from "@merit/frontend-components";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { useLogout } from "../hooks/auth";
import React from "react";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
  readonly showLogoutButton?: boolean;
};

export const PreLoginLayout = ({ children, showLogoutButton = false }: Props) => {
  const { theme } = useTheme();
  const logout = useLogout();

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    content: {
      flex: 1,
    },
    header: {
      alignItems: "center",
      backgroundColor: theme.colors.brand.oceanBlue,
      flexDirection: "row",
      height: 72,
      justifyContent: "center",
    },
    headerButton: {
      position: "absolute",
      right: 32,
      top: 20,
    },
  });

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <View style={styles.header}>
          <MeritLogo height={22} width={72} />
          {showLogoutButton && (
            <View style={styles.headerButton}>
              <Button
                iconLeft="logoutMediumDefault"
                onPress={() => {
                  logout();
                }}
                size="small"
                text="Logout"
                type="secondary"
              />
            </View>
          )}
        </View>

        <View style={styles.content}>{children}</View>
      </SafeAreaView>
    </View>
  );
};
