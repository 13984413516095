import { Heading, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../Spacer";
import type { ReactNode } from "react";

type Props = {
  readonly rightElements?: ReactNode;
  readonly title: string;
};

export const LoggedInHeader = ({ rightElements, title }: Props) => {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      justifyContent: "space-between",
      paddingHorizontal: 32,
      paddingTop: theme.spacing.l,
    },
  });

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Heading bold level="2">
          {title}
        </Heading>
        {rightElements}
      </View>
      <VerticalSpacer size={theme.spacing.s} />
    </View>
  );
};
