// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Modal } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components/Datagrid";
import { ExtendQespStatusModal } from "../../components/ExtendQespStatusModal/ExtendQespStatusModal";
import { Helpers } from "@merit/frontend-utils";
import { Pagination } from "@mui/material";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin } from "../../components/Spin";
import { UploadFileModal } from "../../components/UploadFileModal/UploadFileModal";
import { useApi } from "../../api/api";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { GetUnextendedQesps200ResponseUnextendedQespsInner } from "../../gen";

type UnextendedQespResponse = Required<GetUnextendedQesps200ResponseUnextendedQespsInner>;
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const ROWS_PER_PAGE = 50;

export const ExtendQespTemplate = () => {
  const [qespList, setQespList] = useState<readonly UnextendedQespResponse[]>();
  const [filteredQespList, setFilteredQespList] = useState<readonly UnextendedQespResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedQesp, setSelectedQesp] = useState<
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    UnextendedQespResponse & {
      readonly fileName?: string;
    }
  >();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showQespStatusModal, setShowQespStatusModal] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUploadModalError] = useState<Error>();
  const [currentPage, setCurrentPage] = useState(1);
  const { None, Some } = Helpers;

  const { accessToken, api, idToken } = useApi();
  const { errorHandler } = useServerErrorHandler();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      flex: 1,
      flexDirection: "column",
    },
    paginationContainer: {
      alignItems: "center",
      padding: 25,
    },
  });

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    if (Some(qespList)) {
      const startIndex = ROWS_PER_PAGE * newPage - ROWS_PER_PAGE;
      const endIndex = ROWS_PER_PAGE * newPage - 1;
      setFilteredQespList([...qespList.slice(startIndex, endIndex)]);
    }
    setCurrentPage(newPage);
  };

  const columns: readonly DatagridColumn<UnextendedQespResponse>[] = [
    { key: "orgName", label: "Organisation Name", size: "flex" },
    { key: "orgId", label: "Organisation ID", size: 360 },

    {
      key: "action",
      label: "Actions",
      renderer: qesp => (
        <View style={{ maxWidth: 80 }}>
          <Button
            onPress={() => {
              if (Some(qesp)) {
                setSelectedQesp(qesp);
              }
              setShowUploadModal(true);
            }}
            size="small"
            text="Upload"
            type="secondary"
          />
        </View>
      ),

      size: 120,
    },
  ];

  useEffect(() => {
    const getOrgList = async () => {
      try {
        setLoading(true);
        const res = await api.getUnextendedQesps();
        if (Some(res)) {
          const unextendedQesp = res.unextendedQesps as readonly UnextendedQespResponse[];
          setQespList(unextendedQesp);
          setFilteredQespList([...unextendedQesp.slice(0, ROWS_PER_PAGE - 1)]);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);

        errorHandler(error);
      }
    };

    if (Some(accessToken) && idToken !== "") {
      getOrgList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <View style={styles.container}>
      <>
        <View>
          <ScrollView>
            {loading ? (
              <View style={{ justifyContent: "center", minHeight: 200 }}>
                <Spin />
              </View>
            ) : (
              <Datagrid columns={columns}>
                {Some(filteredQespList) && filteredQespList.length > 0 && (
                  <DatagridBody columns={columns} data={filteredQespList} />
                )}
              </Datagrid>
            )}
          </ScrollView>
        </View>

        <View style={styles.paginationContainer}>
          {Some(qespList) && (
            <Pagination
              count={Math.ceil(qespList.length / ROWS_PER_PAGE)}
              onChange={handleChangePage}
              page={currentPage}
              shape="rounded"
              variant="outlined"
            />
          )}
        </View>
      </>
      {showUploadModal && (
        <Modal
          onClose={() => {
            setShowUploadModal(false);
          }}
          title="Upload"
        >
          <UploadFileModal
            fileTypeName="csv"
            fileTypes={["text/csv"]}
            onCancel={() => {
              setShowUploadModal(false);
            }}
            onSave={selectedFile => {
              if (!Boolean(selectedFile.canceled)) {
                if (Some(selectedFile.assets) && Some(selectedFile.assets[0])) {
                  const fileTextPromise = selectedFile.assets[0].file?.text();
                  setShowUploadModal(false);
                  setShowQespStatusModal(true);
                  if (fileTextPromise === undefined) {
                    setUploadModalError(new Error("Error loading file."));

                    return;
                  }
                  fileTextPromise.then(fileName => {
                    if (None(selectedQesp)) {
                      throw new Error("Somehow trying to extend QESP without selecting the org");
                    }

                    setSelectedQesp({ ...selectedQesp, fileName });
                  });
                }
              }
            }}
            validFileTypes={["csv"]}
          />
        </Modal>
      )}

      {showQespStatusModal && Some(selectedQesp) && Some(selectedQesp.fileName) && (
        <Modal
          onClose={() => {
            setShowQespStatusModal(false);
          }}
          title="Extend QESP Status"
        >
          <ExtendQespStatusModal
            fileName={selectedQesp.fileName}
            orgId={selectedQesp.orgId}
            orgName={selectedQesp.orgName}
          />
        </Modal>
      )}
    </View>
  );
};
