import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer } from "../Spacer";
import { Image, StyleSheet, TouchableHighlight, View } from "react-native";
import { NavPopoverLink } from "./NavPopoverLink";
import { Popover } from "../Popover/Popover";
import { useAppStore } from "../../stores/appStore";
import { useLogout } from "../../hooks/auth";
import { useNavigation } from "@react-navigation/native";
import React, { useRef, useState } from "react";
import type { IconName } from "@merit/frontend-components";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

type PopoverNavOption = {
  readonly icon: IconName;
  readonly name: PopoverNavName;
  readonly title: string;
};

type PopoverNavName = "AppSelect" | "LogOut";

const popoverNavOptions: readonly PopoverNavOption[] = [
  {
    icon: "switchMediumDefault",
    name: "AppSelect",
    title: "Switch applications",
  },
  {
    icon: "logoutMediumDefault",
    name: "LogOut",
    title: "Log out",
  },
];

export const Nav = () => {
  const { theme } = useTheme();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();
  const popoverOriginRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [navExpanded, setNavExpanded] = useState<boolean>(true);
  const logout = useLogout();
  const { selectedApp } = useAppStore();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: "white",
      borderColor: theme.colors.border.default,
      borderRightWidth: 1,
    },
    expandButton: {
      alignItems: "center",
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      height: 40,
      justifyContent: "center",
      width: 40,
    },
    header: {
      alignItems: "center",
      borderBottomWidth: 1,
      borderColor: "rgba(0,0,0,0.2)",
      flexDirection: "row",
      height: 72,
      justifyContent: "space-between",
      paddingHorizontal: theme.spacing.xxl,
      paddingVertical: theme.spacing.l,
      position: "relative",
    },
  });

  const handlePopoverLinkPress = (linkName: PopoverNavName) => {
    setIsPopoverOpen(false);
    switch (linkName) {
      case "LogOut":
        logout();
        break;
      case "AppSelect":
        navigation.navigate("AppSelect");
        break;
      default:
    }
  };

  return (
    <>
      <View style={styles.container}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            transition: "width 300ms",
            transitionTimingFunction: "ease-out",
            width: navExpanded ? 250 : 72,
          }}
        >
          <View style={styles.header}>
            <View style={{ flexDirection: "row" }}>
              <Image source={theme.images.orgLogo} style={{ height: 20, width: 20 }} />
              {navExpanded && (
                <>
                  <HorizontalSpacer />
                  <View style={{ maxWidth: 150, overflow: "hidden" }}>
                    <Heading level="4" numberOfLines={1}>
                      {selectedApp}
                    </Heading>
                  </View>
                </>
              )}
            </View>

            {navExpanded && (
              <div
                onClick={() => {
                  setIsPopoverOpen(prevState => !prevState);
                }}
                ref={popoverOriginRef}
                style={{ cursor: "pointer" }}
              >
                <Icon name="chevronDownMediumDefault" />
              </div>
            )}
          </View>

          <View style={{ bottom: 0, padding: theme.spacing.l, position: "absolute" }}>
            <TouchableHighlight
              onPress={() => {
                setNavExpanded(!navExpanded);
              }}
              style={styles.expandButton}
            >
              <View>
                <Icon name="chevronLeftMediumAction" />
              </View>
            </TouchableHighlight>
          </View>
        </div>
      </View>

      {isPopoverOpen && (
        <Popover
          originElement={popoverOriginRef.current === null ? undefined : popoverOriginRef.current}
          placement="right-end"
        >
          <View style={{ paddingVertical: 6 }}>
            {popoverNavOptions.map(navOption => (
              <NavPopoverLink
                iconName={navOption.icon}
                isActive={false}
                key={navOption.title}
                onPress={() => {
                  handlePopoverLinkPress(navOption.name);
                }}
                title={navOption.title}
              />
            ))}
          </View>
        </Popover>
      )}
    </>
  );
};
