/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateOrgs200ResponseOrgDetailsInner } from './CreateOrgs200ResponseOrgDetailsInner';
import {
    CreateOrgs200ResponseOrgDetailsInnerFromJSON,
    CreateOrgs200ResponseOrgDetailsInnerFromJSONTyped,
    CreateOrgs200ResponseOrgDetailsInnerToJSON,
} from './CreateOrgs200ResponseOrgDetailsInner';

/**
 * 
 * @export
 * @interface AddOrgAdmins200Response
 */
export interface AddOrgAdmins200Response {
    /**
     * 
     * @type {Array<CreateOrgs200ResponseOrgDetailsInner>}
     * @memberof AddOrgAdmins200Response
     */
    adminDetails: Array<CreateOrgs200ResponseOrgDetailsInner> | null;
}

/**
 * Check if a given object implements the AddOrgAdmins200Response interface.
 */
export function instanceOfAddOrgAdmins200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "adminDetails" in value;

    return isInstance;
}

export function AddOrgAdmins200ResponseFromJSON(json: any): AddOrgAdmins200Response {
    return AddOrgAdmins200ResponseFromJSONTyped(json, false);
}

export function AddOrgAdmins200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrgAdmins200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminDetails': (json['adminDetails'] === null ? null : (json['adminDetails'] as Array<any>).map(CreateOrgs200ResponseOrgDetailsInnerFromJSON)),
    };
}

export function AddOrgAdmins200ResponseToJSON(value?: AddOrgAdmins200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminDetails': (value.adminDetails === null ? null : (value.adminDetails as Array<any>).map(CreateOrgs200ResponseOrgDetailsInnerToJSON)),
    };
}

