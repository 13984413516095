/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddOrgAdminsRequestOrgsInner } from './AddOrgAdminsRequestOrgsInner';
import {
    AddOrgAdminsRequestOrgsInnerFromJSON,
    AddOrgAdminsRequestOrgsInnerFromJSONTyped,
    AddOrgAdminsRequestOrgsInnerToJSON,
} from './AddOrgAdminsRequestOrgsInner';

/**
 * 
 * @export
 * @interface AddOrgAdminsRequest
 */
export interface AddOrgAdminsRequest {
    /**
     * 
     * @type {string}
     * @memberof AddOrgAdminsRequest
     */
    filename: string;
    /**
     * 
     * @type {Array<AddOrgAdminsRequestOrgsInner>}
     * @memberof AddOrgAdminsRequest
     */
    orgs: Array<AddOrgAdminsRequestOrgsInner>;
}

/**
 * Check if a given object implements the AddOrgAdminsRequest interface.
 */
export function instanceOfAddOrgAdminsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "orgs" in value;

    return isInstance;
}

export function AddOrgAdminsRequestFromJSON(json: any): AddOrgAdminsRequest {
    return AddOrgAdminsRequestFromJSONTyped(json, false);
}

export function AddOrgAdminsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddOrgAdminsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'orgs': ((json['orgs'] as Array<any>).map(AddOrgAdminsRequestOrgsInnerFromJSON)),
    };
}

export function AddOrgAdminsRequestToJSON(value?: AddOrgAdminsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'orgs': ((value.orgs as Array<any>).map(AddOrgAdminsRequestOrgsInnerToJSON)),
    };
}

