import { Button, Modal } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { ResponseError } from "../../gen";
import { Spin } from "../../components/Spin";
import { Text, View } from "react-native";
import { UploadFileModal } from "../../components/UploadFileModal/UploadFileModal";
import { useApi } from "../../api/api";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useUpload } from "../../utils/useUpload";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, { useState } from "react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import type {
  AddOrgAdminsRequestOrgsInner,
  CreateOrgs200Response,
  CreateOrgs200ResponseOrgDetailsInner,
} from "../../gen";

const steps = [
  "Get csv upload link",
  "Upload csv to bucket",
  "Add org uuid to csv",
  "Create org",
  "Add org admin",
  "Issue verified qesp folio",
];

export const CreateOrganization = () => {
  const { None, Some } = Helpers;
  const { api } = useApi();
  const [showUploadModal, setShowUploadModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUploadModalError] = useState<Error>();
  const [showStepBar, setShowStepBar] = useState<boolean>(false);
  const [step, setStep] = useState<number>(-1);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const csvUpload = useUpload();
  const { errorHandler } = useServerErrorHandler();
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  const [orgDetailsResponse, setOrgDetailsResponse] = useState<CreateOrgs200Response | undefined>();
  const [adminDetailsResponse, setAdminDetailsResponse] = useState<
    readonly CreateOrgs200ResponseOrgDetailsInner[] | null
  >();
  const [firstStepContent, setFirstStepContent] = useState<string>("");
  const [secondStepContent, setSecondStepContent] = useState<string>("");
  const [thirdStepContent, setThirdStepContent] = useState<string>("");
  // fourthStepContent --> orgDetailsResponse & fifthStepContent --> adminDetailsResponse
  const [sixthStepContent, setSixthStepContent] = useState<string>("");
  const [errorResponse, setErrorResponse] = useState<string>("");
  const [filenameWithOrgUUID, setFilenameWithOrgUUID] = useState<string>("");
  const [showRetryButton, setShowRetryButton] = useState<boolean>(false);

  const resetStates = () => {
    setShowStepBar(true);
    setStep(0);
    setFirstStepContent("");
    setSecondStepContent("");
    setThirdStepContent("");
    setOrgDetailsResponse(undefined);
    setAdminDetailsResponse(undefined);
    setSixthStepContent("");
    setErrorResponse("");
    setShowRetryButton(false);
  };

  const createOrganization = async (fileContent: string) => {
    try {
      resetStates();

      const { filename, link } = await api.getCSVUploadLink({ uploadType: "org" });
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setFirstStepContent(`Received upload link with filename in google storage: ${filename}`);

      setStep(prevState => prevState + 1);
      await csvUpload.upload(link, fileContent);
      setSecondStepContent("Successfully uploaded the file");

      setStep(prevState => prevState + 1);
      const { filenameWithOrgUUID: filenameWithOrgUUIDFromApi } = await api.addOrgUUIDToCSV({
        filename: { filename },
      });
      setFilenameWithOrgUUID(filenameWithOrgUUIDFromApi);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setThirdStepContent(`Filename with org uuid: ${filenameWithOrgUUIDFromApi}`);

      setStep(prevState => prevState + 1);
      const orgRes = await api.createOrgs({ filename: { filename: filenameWithOrgUUIDFromApi } });
      const { orgDetails } = orgRes;
      if (None(orgDetails)) {
        throw new Error("Org details cannot be null");
      }
      setOrgDetailsResponse(orgRes);

      // eslint-disable-next-line functional/prefer-readonly-type
      const orgsForAdminCreation: AddOrgAdminsRequestOrgsInner[] = [];

      if (Some(orgRes) && Some(orgRes.orgDetails) && orgRes.orgDetails.length > 0) {
        orgRes.orgDetails.forEach(each => {
          if (each.authorizationStatus === "success") {
            // eslint-disable-next-line functional/immutable-data
            orgsForAdminCreation.push({ orgName: each.orgName, orgUUID: each.orgUUID });
          }
        });
      }

      if (orgsForAdminCreation.length === 0) {
        return;
      }

      setStep(prevState => prevState + 1);
      const { adminDetails } = await api.addOrgAdmins({
        requestBody: { filename: filenameWithOrgUUIDFromApi, orgs: orgsForAdminCreation },
      });
      if (None(adminDetails)) {
        throw new Error("Admin details cannot be null");
      }
      setAdminDetailsResponse(adminDetails);

      setStep(prevState => prevState + 1);
      await api.issueVerifiedQespFolios({
        qesps: { filenameWithOrgId: filenameWithOrgUUIDFromApi },
      });
      setSixthStepContent("Successfully issued verified QESP folios");
      setStep(prevState => prevState + 1);
    } catch (error) {
      setShowRetryButton(true);
      if (error instanceof ResponseError) {
        const responseError = await error.response.json();
        setErrorResponse(responseError.message ?? "Something went wrong");

        return;
      }
      errorHandler(error);
    } finally {
      setIsUploading(false);
    }
  };

  const retryCreateOrganization = async () => {
    try {
      setShowStepBar(true);
      setIsUploading(true);
      setShowRetryButton(false);
      setErrorResponse("");

      if (step === 3) {
        const orgRes = await api.createOrgs({ filename: { filename: filenameWithOrgUUID } });
        const { orgDetails } = orgRes;
        if (None(orgDetails)) {
          throw new Error("Org details cannot be null");
        }
        setOrgDetailsResponse(orgRes);

        setStep(prevState => prevState + 1);
      }
      if (step === 3 || step === 4) {
        // eslint-disable-next-line functional/prefer-readonly-type
        const orgsForAdminCreation: AddOrgAdminsRequestOrgsInner[] = [];

        if (
          Some(orgDetailsResponse) &&
          Some(orgDetailsResponse.orgDetails) &&
          orgDetailsResponse.orgDetails.length > 0
        ) {
          orgDetailsResponse.orgDetails.forEach(each => {
            if (each.authorizationStatus === "success") {
              // eslint-disable-next-line functional/immutable-data
              orgsForAdminCreation.push({ orgName: each.orgName, orgUUID: each.orgUUID });
            }
          });
        }

        const { adminDetails } = await api.addOrgAdmins({
          requestBody: { filename: filenameWithOrgUUID, orgs: orgsForAdminCreation },
        });
        if (None(adminDetails)) {
          throw new Error("Admin details cannot be null");
        }
        setAdminDetailsResponse(adminDetails);

        setStep(prevState => prevState + 1);
      }
      if (step === 4 || step === 5) {
        await api.issueVerifiedQespFolios({
          qesps: { filenameWithOrgId: filenameWithOrgUUID },
        });
        setSixthStepContent("Successfully issued verified QESP folios");
        setStep(prevState => prevState + 1);
      }
    } catch (error) {
      setShowRetryButton(true);
      if (error instanceof ResponseError) {
        const responseError = await error.response.json();
        setErrorResponse(responseError.message ?? "Something went wrong");

        return;
      }
      errorHandler(error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <View>
        <View>
          <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
            <Button
              disabled={isUploading}
              onPress={() => {
                setShowUploadModal(true);
              }}
              text="Upload"
            />
          </View>
          <View style={{ marginTop: 50 }}>{isUploading && <Spin />}</View>
          {showStepBar && (
            <View>
              <View>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={step} alternativeLabel>
                    {steps.map(label => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </View>
              <View>
                <View style={{ marginTop: 20 }}>
                  {firstStepContent !== "" && (
                    <Text style={{ margin: 10 }}>Summary of step-1: {firstStepContent}</Text>
                  )}
                  {secondStepContent !== "" && (
                    <Text style={{ margin: 10 }}>Summary of step-2: {secondStepContent}</Text>
                  )}
                  {thirdStepContent !== "" && (
                    <Text style={{ margin: 10 }}>Summary of step-3: {thirdStepContent}</Text>
                  )}
                  {Some(orgDetailsResponse) && Some(orgDetailsResponse.orgDetails) && (
                    <View style={{ margin: 10 }}>
                      <Text>Summary of step-4: Org details</Text>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Status</TableCell>
                              <TableCell align="right">Container ID</TableCell>
                              <TableCell align="right">Org ID</TableCell>
                              <TableCell align="right">Org Name</TableCell>
                              <TableCell align="right">Org UUID</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Some(orgDetailsResponse) &&
                              Some(orgDetailsResponse.orgDetails) &&
                              orgDetailsResponse.orgDetails.map(row => (
                                <TableRow
                                  key={row.containerId}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Text
                                      style={{
                                        color: row.authorizationStatus === "fail" ? "red" : "black",
                                        margin: 10,
                                      }}
                                    >
                                      {row.authorizationStatus}

                                      {row.authorizationStatus === "fail" && row.error !== undefined
                                        ? ` : ${row.error as string}`
                                        : ""}
                                    </Text>
                                  </TableCell>

                                  <TableCell align="right">{row.containerId}</TableCell>
                                  <TableCell align="right">{row.orgId}</TableCell>
                                  <TableCell align="right">{row.orgName}</TableCell>
                                  <TableCell align="right">{row.orgUUID}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </View>
                  )}
                  {Some(adminDetailsResponse) && (
                    <View style={{ margin: 10 }}>
                      <Text>Summary of step-5: Admin details</Text>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Status</TableCell>
                              <TableCell align="right">Container ID</TableCell>
                              <TableCell align="right">Org Name</TableCell>
                              <TableCell align="right">Org UUID</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Some(adminDetailsResponse) &&
                              adminDetailsResponse.map(row => (
                                <TableRow
                                  key={row.containerId}
                                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.authorizationStatus}
                                  </TableCell>
                                  <TableCell align="right">{row.containerId}</TableCell>
                                  <TableCell align="right">{row.orgName}</TableCell>
                                  <TableCell align="right">{row.orgUUID}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </View>
                  )}
                  {sixthStepContent !== "" && (
                    <Text style={{ margin: 10 }}>Summary of step-6: {sixthStepContent}</Text>
                  )}
                  {errorResponse !== "" && (
                    <Text style={{ color: "red", margin: 10 }}>{errorResponse}</Text>
                  )}
                </View>
              </View>
              {showRetryButton && step >= 3 && (
                <View
                  style={{
                    alignItems: "center",
                    flex: 0.2,
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  <Button
                    onPress={() => {
                      retryCreateOrganization();
                    }}
                    size="medium"
                    text="Retry"
                  />
                </View>
              )}
            </View>
          )}
        </View>

        {showUploadModal && (
          <Modal
            onClose={() => {
              setShowUploadModal(false);
            }}
            title="Upload"
          >
            <UploadFileModal
              fileTypeName="csv"
              fileTypes={["text/csv"]}
              onCancel={() => {
                setShowUploadModal(false);
              }}
              onSave={selectedFile => {
                if (!Boolean(selectedFile.canceled)) {
                  if (Some(selectedFile.assets) && Some(selectedFile.assets[0])) {
                    const fileTextPromise = selectedFile.assets[0].file?.text();
                    setIsUploading(true);
                    setShowUploadModal(false);
                    if (fileTextPromise === undefined) {
                      setUploadModalError(new Error("Error loading file."));

                      return;
                    }
                    fileTextPromise.then(fileName => {
                      createOrganization(fileName);
                    });
                  }
                }
              }}
              validFileTypes={["csv"]}
            />
          </Modal>
        )}
      </View>
    </>
  );
};
