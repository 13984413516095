import { AlertContainer } from "@merit/frontend-components";
import { AppSelectScreen } from "./screens/AppSelect";
import { DashboardScreen } from "./screens/Dashboard/Dashboard";
import { LoginScreen } from "./screens/LoginScreen";
import { NavigationContainer } from "@react-navigation/native";
import { NotEligibleScreen } from "./screens/ErrorScreen/NotEligibleScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useAlertStore } from "./stores/alertStore";
import { useAppStore } from "./stores/appStore";
import { useMeritAuth0 } from "./hooks/auth";
import React from "react";

export type PreLoginRouteParams = {
  readonly Landing: undefined;
};

export type PostLoginRouteParams = {
  // Screens
  readonly Dashboard: undefined;
  readonly AppSelect: undefined;
  readonly NotEligible: undefined;
};

export type RouteParams = PostLoginRouteParams & PreLoginRouteParams;

export const PRE_LOGIN_ROUTES: Record<keyof PreLoginRouteParams, string> = {
  Landing: "/",
};

export const LOGGED_IN_ROUTES: Record<keyof PostLoginRouteParams, string> = {
  AppSelect: "/app-select",
  Dashboard: "/dashboard",
  NotEligible: "/not-eligible",
};

export const ROUTES: Record<keyof RouteParams, string> = {
  ...PRE_LOGIN_ROUTES,
  ...LOGGED_IN_ROUTES,
};

const QespNavigator = createNativeStackNavigator<RouteParams>();

const linking = {
  config: {
    screens: ROUTES,
  },
  prefixes: [""],
};

const LoggedInScreens = (
  <QespNavigator.Group screenOptions={{ headerShown: false }}>
    <QespNavigator.Screen component={AppSelectScreen} name="AppSelect" />
    <QespNavigator.Screen component={DashboardScreen} name="Dashboard" />
    <QespNavigator.Screen component={NotEligibleScreen} name="NotEligible" />
  </QespNavigator.Group>
);

const LoggedOutScreens = (
  <QespNavigator.Group screenOptions={{ headerShown: false }}>
    <QespNavigator.Screen component={LoginScreen} name="Landing" />
  </QespNavigator.Group>
);

export const Navigator = () => {
  const alertList = useAlertStore(state => state.alertList);
  const { isAuthenticated } = useMeritAuth0();
  const { isToSAccepted } = useAppStore();
  const screens =
    isAuthenticated && isToSAccepted ? <>{LoggedInScreens}</> : <>{LoggedOutScreens}</>;
  const initialRouteName = isAuthenticated && isToSAccepted ? "AppSelect" : "Landing";

  return (
    <NavigationContainer linking={linking}>
      <QespNavigator.Navigator initialRouteName={initialRouteName}>
        {screens}
      </QespNavigator.Navigator>
      <AlertContainer alertList={alertList} />
    </NavigationContainer>
  );
};
