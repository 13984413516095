import { SpinIndicator } from "./SpinIndicator";
import { StyleSheet, View } from "react-native";
import type { SpinIndicatorProps } from "./SpinIndicator";

export type SpinProps = SpinIndicatorProps & {
  readonly spinning?: boolean;
  readonly children?: React.ReactNode;
};

const styles = StyleSheet.create({
  blur: {
    opacity: 0.5,
  },
  overlay: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
  wrapper: {
    flex: 1,
    position: "relative",
  },
});

export const Spin = ({ children, color, size, spinning = false }: SpinProps) => {
  const indicator = <SpinIndicator color={color} size={size} />;

  return children === undefined ? (
    indicator
  ) : (
    <View style={styles.wrapper}>
      <View style={[spinning && styles.blur, { flex: 1 }]}>{children}</View>
      {spinning && <View style={styles.overlay}>{indicator}</View>}
    </View>
  );
};
