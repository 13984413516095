import { CreateOrganization } from "./CreateOrganization";
import { DefaultLayout } from "../../layouts/DefaultLayout";
import { ExtendQespTemplate } from "../ExtendQespTemplate";
import { ScrollView, StyleSheet, View } from "react-native";
import { Tabs } from "../../components/Tabs";
import { VerticalSpacer } from "../../components/Spacer";
import { useTheme } from "@merit/frontend-components";
import React, { useState } from "react";

type TabName = "createOrg" | "extendQesp";
const tabs: readonly { readonly key: TabName; readonly label: string }[] = [
  {
    key: "createOrg",
    label: "Create Organization",
  },
  { key: "extendQesp", label: "Extend QESP" },
];

export const DashboardScreen = () => {
  const { theme } = useTheme();
  const [selectedTab, setSelectedTab] = useState<TabName>(tabs[0].key);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setUploadModalError] = useState<Error>();

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
    },
    mappingsTooltip: {
      backgroundColor: theme.colors.brand.oceanBlue,
      borderRadius: theme.spacing.xs,
      padding: theme.spacing.m,
      width: 200,
    },
    statusIndicator: {
      backgroundColor: theme.colors.brand.citrine,
      borderRadius: theme.borderRadii.m,
      height: 8,
      marginRight: theme.spacing.m,
      width: 8,
    },
  });

  return (
    <>
      <DefaultLayout
        tabs={
          <Tabs
            items={tabs}
            onChange={tab => {
              setSelectedTab(tab);
            }}
            selected={selectedTab}
          />
        }
        title="QESP Onboarding"
      >
        <View style={styles.container}>
          <VerticalSpacer size={theme.spacing.xxl} />
          <>
            <View style={{ flex: 1 }}>
              <View style={{ marginLeft: 32 }}>
                <ScrollView>
                  {selectedTab === "createOrg" && <CreateOrganization />}

                  {selectedTab === "extendQesp" && (
                    <View>
                      <ExtendQespTemplate />
                    </View>
                  )}
                </ScrollView>
              </View>
            </View>
          </>
        </View>
      </DefaultLayout>
      <View />
    </>
  );
};
