// Copyright 2023 Merit International Inc. All Rights Reserved
import * as Sentry from "sentry-expo";
import { ResponseError } from "../gen";
import { useAlertStore } from "../stores/alertStore";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

export const useServerErrorHandler = () => {
  const { deleteAlert, setAlert } = useAlertStore();
  const errorHandler = useCallback(
    async (err: unknown) => {
      if (err instanceof ResponseError) {
        const error = await err.response.json();
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: error.message ?? "Something went wrong",
          type: "error",
        });
        Sentry.Browser.captureEvent({
          level: "error",
          message: error.message ?? "Something went wrong",
        });
      }
    },
    [deleteAlert, setAlert]
  );

  return { errorHandler };
};
