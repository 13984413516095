// Copyright 2023 Merit International Inc. All Rights Reserved

import { AgentClient } from "../../services/AgentClient";
import { Button } from "@merit/frontend-components";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import { Helpers } from "@merit/frontend-utils";
import { Icons } from "../../utils/Icon";
import {
  Image,
  Modal,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Images } from "../../utils/Image";
import { Spin } from "../Spin";
import { config } from "../../config/config";
import { useAppStore } from "../../stores/appStore";
import { useDefaultErrorHandler } from "../../utils/useDefaultErrorHandler";
import { useLogout, useMeritAuth0 } from "../../hooks/auth";
import Checkbox from "expo-checkbox";
import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import type { MeritUserInfo } from "../../types/user";

const { Some } = Helpers;

export const TermsOfServiceModal = () => {
  const logoutFromAuth0 = useLogout();
  const { errorHandler } = useDefaultErrorHandler();
  const [isVisible, setIsVisible] = useState(true);
  const [showMoreInfoTooltip, setShowMoreInfoTooltip] = useState(false);
  const { accessToken, user } = useMeritAuth0();
  const [isToSChecked, setToSChecked] = useState(false);
  const { setIsToSAccepted } = useAppStore();

  const styles = StyleSheet.create({
    backButton: {
      alignItems: "flex-start",
    },
    container: {
      backgroundColor: "#FFFFFF",
      borderRadius: 4,
      paddingHorizontal: 32,
      paddingVertical: 32,
      width: "30%",
    },
    footer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingBottom: 30,
      width: "100%",
    },
    footerLinks: {
      textDecorationLine: "underline",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 12,
      width: "100%",
    },
    headerContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 44,
    },
    headerText: {
      fontSize: 24,
      fontWeight: "700",
    },
    listAsterisk: {
      fontSize: 16,
      fontWeight: "500",
    },
    tooltip: {
      backgroundColor: "#007A98",
      borderRadius: 4,
      ...(Platform.OS === "web" && { boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)" }),
      paddingHorizontal: 16,
      paddingVertical: 12,
      position: "absolute",
      shadowColor: "rgba(0, 0, 0, 0.1)",
      shadowOpacity: 0.8,
      shadowRadius: 4,
      width: 200,
      zIndex: 2,
    },
    viewMeritListItem: {
      borderBottomWidth: 1,
      borderColor: "#E2E6E8",
      borderTopWidth: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 20,
    },
    viewNameEmailListItem: {
      borderColor: "#E2E6E8",
      borderTopWidth: 1,
      justifyContent: "center",
      paddingVertical: 20,
    },
    wrapper: {
      alignItems: "center",
      backgroundColor: "rgba(0,0,0, 0.5)",
      flex: 1,
      justifyContent: "center",
    },
  });

  const onCancel = () => {
    logoutFromAuth0();
  };

  const acceptToSAndLinkAgent = async (token: string) => {
    const agentClient = AgentClient(token);
    const { agentID, entityID } = jwt_decode<MeritUserInfo>(token);

    try {
      await agentClient.acceptToS(agentID);
      await agentClient.linkEntity(agentID, entityID);
    } catch (error) {
      errorHandler(error);
    }
  };

  const onConfirm = async () => {
    setIsVisible(false);
    try {
      if (Some(accessToken)) {
        await acceptToSAndLinkAgent(accessToken);
        setIsToSAccepted(true);
      } else {
        throw new Error("Invalid/Empty access token");
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  return (
    <View style={{ alignContent: "center", alignItems: "center", flex: 1 }}>
      <View style={{ marginTop: 50 }}>
        <Spin />
      </View>
      <Modal animationType="fade" onRequestClose={onCancel} transparent visible={isVisible}>
        <View style={styles.wrapper}>
          <View style={styles.container}>
            <View style={styles.headerContainer}>
              <Pressable onPress={onCancel}>
                <Image
                  accessibilityLabel="Back arrow"
                  accessible
                  source={Icons.backArrowGray}
                  style={{ height: 24, width: 24 }}
                />
              </Pressable>
              <Image
                accessibilityLabel="MeritLogo"
                accessible
                resizeMode="contain"
                source={Images.meritPlatformLogo}
                style={{ height: 40, width: 76 }}
              />
              <View style={{ width: 24 }} />
            </View>
            <View style={{ alignItems: "center", paddingVertical: 40 }}>
              <Image
                accessibilityLabel="UserMeritRelationLogo"
                accessible
                resizeMode="contain"
                source={Images.userMeritRelation}
                style={{ height: 76, width: 230 }}
              />
            </View>
            <View style={styles.header}>
              <Text style={styles.headerText}>{config.name}</Text>
            </View>
            <View>
              <Text style={{ lineHeight: 22, paddingBottom: 10, paddingTop: 5 }}>
                is requesting access to your Merit account
                {Some(user) && Some(user.email) && (
                  <>
                    <Text> on behalf of</Text>{" "}
                    <Text style={{ fontWeight: "600" }}>{user.email}</Text>
                  </>
                )}
              </Text>
            </View>
            <View style={{ paddingVertical: 10 }}>
              <View style={styles.viewNameEmailListItem}>
                <Text style={styles.listAsterisk}>
                  <Text style={{ fontSize: 20, marginRight: 10, textAlignVertical: "center" }}>
                    *
                  </Text>
                  View your name and primary email
                </Text>
              </View>
              <View style={styles.viewMeritListItem}>
                <Text style={styles.listAsterisk}>
                  <Text style={{ fontSize: 20, marginRight: 10, textAlignVertical: "center" }}>
                    *
                  </Text>
                  View all your merits
                </Text>
                {showMoreInfoTooltip && (
                  <View style={[styles.tooltip, { top: 40 }]}>
                    <Text style={{ color: "#FFFFFF" }}>
                      This includes all accepted and pending merits
                    </Text>
                  </View>
                )}
                <TouchableOpacity
                  onPress={() => {
                    setShowMoreInfoTooltip(prevState => !prevState);
                  }}
                >
                  <Image
                    accessible
                    source={Icons.informationOutlinedMediumDefault}
                    style={{ height: 20, marginRight: 20, width: 20 }}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={{ flexDirection: "row", paddingVertical: 25, width: "100%" }}>
              <Checkbox
                onValueChange={() => {
                  setToSChecked(prevState => !prevState);
                }}
                value={isToSChecked}
              />
              <View style={{ flexDirection: "row", paddingHorizontal: 5 }}>
                <Text>Accept </Text>
                <ExternalLink
                  text="Terms of Service"
                  textStyle={{ textDecorationLine: "underline" }}
                  url="https://app.merits.com/terms-of-service"
                />
              </View>
            </View>
            <View style={styles.footer}>
              <Button onPress={onCancel} text="Cancel" type="secondary" />
              <View style={{ width: 16 }} />
              <Button
                disabled={!isToSChecked}
                onPress={() => {
                  onConfirm();
                }}
                text="Allow"
              />
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};
