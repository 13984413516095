/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetEligibleApps200ResponseOrgsInner
 */
export interface GetEligibleApps200ResponseOrgsInner {
    /**
     * 
     * @type {string}
     * @memberof GetEligibleApps200ResponseOrgsInner
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof GetEligibleApps200ResponseOrgsInner
     */
    orgID: string;
}

/**
 * Check if a given object implements the GetEligibleApps200ResponseOrgsInner interface.
 */
export function instanceOfGetEligibleApps200ResponseOrgsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgName" in value;
    isInstance = isInstance && "orgID" in value;

    return isInstance;
}

export function GetEligibleApps200ResponseOrgsInnerFromJSON(json: any): GetEligibleApps200ResponseOrgsInner {
    return GetEligibleApps200ResponseOrgsInnerFromJSONTyped(json, false);
}

export function GetEligibleApps200ResponseOrgsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEligibleApps200ResponseOrgsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgName': json['orgName'],
        'orgID': json['orgID'],
    };
}

export function GetEligibleApps200ResponseOrgsInnerToJSON(value?: GetEligibleApps200ResponseOrgsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgName': value.orgName,
        'orgID': value.orgID,
    };
}

