import { AppSelectLink } from "./AppSelectLink";
import { Body, Heading, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { PreLoginLayout } from "../../layouts/PreLoginLayout";
import { ScrollView, StyleSheet, View } from "react-native";
import { Spin } from "../../components/Spin";
import { VerticalSpacer } from "../../components/Spacer";
import { useApi } from "../../api/api";
import { useAppStore } from "../../stores/appStore";
import { useNavigation } from "@react-navigation/native";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import React, { useEffect, useState } from "react";
import type { GetEligibleApps200Response } from "../../gen/models";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";

const styles = StyleSheet.create({
  appsView: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "auto",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginBottom: 16,
    marginTop: 40,
  },
});

type Navigation = NativeStackNavigationProp<RouteParams>;

export const AppSelectScreen = () => {
  const { theme } = useTheme();
  const { setSelectedApp } = useAppStore();
  const navigation = useNavigation<Navigation>();
  const { accessToken, api, idToken } = useApi();
  const { Some } = Helpers;
  const { errorHandler } = useServerErrorHandler();
  const [eligibleApps, setEligibleApps] = useState<GetEligibleApps200Response>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getEligibleApps = async () => {
      try {
        setIsLoading(true);
        const eligibleAppsResponse = await api.getEligibleApps();
        if (eligibleAppsResponse.orgs.length === 0) {
          navigation.navigate("NotEligible");
        } else {
          setEligibleApps(eligibleAppsResponse);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (Some(accessToken) && idToken !== "") {
      getEligibleApps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <PreLoginLayout showLogoutButton>
      <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        <View style={styles.container}>
          <View style={styles.appsView}>
            <View style={{ maxWidth: 420 }}>
              <Heading level="1" numberOfLines={1}>
                Choose an application
              </Heading>
              <VerticalSpacer size={theme.spacing.s} />
              <Body numberOfLines={1} size="l">
                Select which application to log in to
              </Body>

              <VerticalSpacer size={theme.spacing.xxl} />
            </View>
            {isLoading && <Spin />}
            {Some(eligibleApps) &&
              eligibleApps.orgs.length > 0 &&
              eligibleApps.orgs.map(app => (
                <View key={app.orgID}>
                  <AppSelectLink
                    appName={app.orgName}
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    key={`app-select-link-${app.orgID}`}
                    onPress={() => {
                      setSelectedApp(app.orgName);
                      navigation.navigate("Dashboard");
                    }}
                  />
                  <View
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    key={`app-select-link-${app.orgID}-spacer`}
                    style={{ marginBottom: 16 }}
                  />
                </View>
              ))}
          </View>
        </View>
      </ScrollView>
    </PreLoginLayout>
  );
};
