import { Body, Icon, useTheme } from "@merit/frontend-components";
import { StyleSheet, TouchableHighlight, View } from "react-native";
import { useHover } from "react-native-web-hooks";
import React, { useRef } from "react";

type Props = {
  readonly appName: string;
  readonly onPress: () => void;
};

export const AppSelectLink = ({ appName, onPress }: Props) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    base: {
      alignItems: "center",
      backgroundColor: theme.colors.action.secondary.default,
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      minHeight: 64,
      minWidth: 420,
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.m,
    },
    hovered: {
      backgroundColor: theme.colors.surface.hovered,
      borderColor: theme.colors.action.default,
      borderWidth: 1,
    },
  });

  return (
    <>
      <TouchableHighlight key={appName} onPress={onPress} underlayColor="rbga(0,0,0,0)">
        <View ref={ref} style={[styles.base, isHovered && styles.hovered]}>
          <View style={{ maxWidth: 352 }}>
            <div>
              <View style={{ flex: 1 }}>
                <Body numberOfLines={1} size="l">
                  {appName}
                </Body>
              </View>
            </div>
          </View>
          <Icon alt="text" name="arrowForwardMediumAction" />
        </View>
      </TouchableHighlight>
    </>
  );
};
