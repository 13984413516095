/* eslint-disable no-console */
// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button } from "@merit/frontend-components";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { Spin } from "../components/Spin";
import { TermsOfServiceModal } from "../components/TermsOfServiceModal/TermsOfServiceModal";
import { View } from "react-native";
import { useAppStore } from "../stores/appStore";
import { useLogin, useMeritAuth0 } from "../hooks/auth";
import React from "react";

export const LoginScreen = () => {
  const promptLogin = useLogin();
  const { isAuthenticated, isLoading } = useMeritAuth0();
  const { isToSAccepted } = useAppStore();

  return (
    <PreLoginLayout>
      {isLoading && (
        <View style={{ marginTop: 50 }}>
          <Spin />
        </View>
      )}
      {!isAuthenticated && (
        <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
          <Button
            onPress={() => {
              promptLogin();
            }}
            text="Login"
          />
        </View>
      )}
      {isAuthenticated && !isToSAccepted && <TermsOfServiceModal />}
    </PreLoginLayout>
  );
};
