/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEligibleApps200ResponseOrgsInner } from './GetEligibleApps200ResponseOrgsInner';
import {
    GetEligibleApps200ResponseOrgsInnerFromJSON,
    GetEligibleApps200ResponseOrgsInnerFromJSONTyped,
    GetEligibleApps200ResponseOrgsInnerToJSON,
} from './GetEligibleApps200ResponseOrgsInner';

/**
 * 
 * @export
 * @interface GetEligibleApps200Response
 */
export interface GetEligibleApps200Response {
    /**
     * 
     * @type {Array<GetEligibleApps200ResponseOrgsInner>}
     * @memberof GetEligibleApps200Response
     */
    orgs: Array<GetEligibleApps200ResponseOrgsInner>;
}

/**
 * Check if a given object implements the GetEligibleApps200Response interface.
 */
export function instanceOfGetEligibleApps200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "orgs" in value;

    return isInstance;
}

export function GetEligibleApps200ResponseFromJSON(json: any): GetEligibleApps200Response {
    return GetEligibleApps200ResponseFromJSONTyped(json, false);
}

export function GetEligibleApps200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEligibleApps200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orgs': ((json['orgs'] as Array<any>).map(GetEligibleApps200ResponseOrgsInnerFromJSON)),
    };
}

export function GetEligibleApps200ResponseToJSON(value?: GetEligibleApps200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orgs': ((value.orgs as Array<any>).map(GetEligibleApps200ResponseOrgsInnerToJSON)),
    };
}

