// Copyright 2023 Merit International Inc. All Rights Reserved

import { Heading, Icon, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Pressable, StyleSheet, View } from "react-native";
import { Spin } from "../Spin";
import { getColumnWidthStyle } from "./utils";
import { useState } from "react";
import type { ColumnKey, DatagridColumn, DatagridSortState } from "./types";
import type { ReactNode } from "react";

const { Some } = Helpers;

export type DatagridProps<T extends object> = {
  readonly children: ReactNode;
  readonly columns: readonly DatagridColumn<T>[];
  readonly loading?: boolean;

  readonly isSortable?: boolean;
  readonly onSortChange?: (newSorting: DatagridSortState<T>) => void;
  readonly defaultSort?: DatagridSortState<T>;
};

export function Datagrid<T extends object>({
  children,
  columns,
  defaultSort,
  isSortable = false,
  loading = false,
  onSortChange,
}: DatagridProps<T>) {
  const { theme } = useTheme();
  const [sortState, setSortState] = useState<DatagridSortState<T>>(
    defaultSort ?? { direction: "asc", key: "" }
  );

  const styles = StyleSheet.create({
    headerCell: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: theme.spacing.l,
    },
    headerRow: {
      alignItems: "center",
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingHorizontal: theme.spacing.l,
    },
  });

  const handleChangeSort = (columnKey: ColumnKey<T>) => {
    if (sortState.key === columnKey) {
      setSortState({
        ...sortState,
        direction: sortState.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortState({
        direction: "asc",
        key: columnKey,
      });
    }

    // is there an async issue here?
    // eslint-disable-next-line no-unused-expressions
    onSortChange?.(sortState);
  };

  return (
    <Spin spinning={loading}>
      <View style={{ position: "relative" }}>
        <View style={styles.headerRow}>
          {columns.map(column => (
            <Pressable
              key={String(column.key)}
              onPress={() => {
                handleChangeSort(column.key);
              }}
              style={[styles.headerCell, getColumnWidthStyle(column.size)]}
            >
              <View
                style={{ alignItems: "center", flexDirection: "row", justifyContent: "flex-start" }}
              >
                {typeof column.label === "string" ? (
                  <Heading level="5" numberOfLines={1}>
                    {column.label}
                  </Heading>
                ) : (
                  <>{column.label}</>
                )}

                {Some(column.tooltipComponent) && (
                  <>
                    <View style={{ marginLeft: theme.spacing.l }} />
                    {column.tooltipComponent}
                  </>
                )}
              </View>

              {isSortable && sortState.key === column.key && (
                <View style={{ marginLeft: theme.spacing.l, transform: [{ rotate: "90deg" }] }}>
                  <Icon
                    name={
                      sortState.direction === "asc"
                        ? "arrowForwardMediumAction"
                        : "arrowBackwardMediumAction"
                    }
                  />
                </View>
              )}
            </Pressable>
          ))}
        </View>
        {children}
      </View>
    </Spin>
  );
}
