// Copyright 2023 Merit International Inc. All Rights Reserved

import { useServerErrorHandler } from "./useServerErrorHandler";
import { useState } from "react";

export const useUpload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { errorHandler } = useServerErrorHandler();

  const upload = async (link: string, fileUri: string) => {
    try {
      setLoading(true);

      await fetch(link, {
        body: fileUri,
        headers: {
          "Content-Type": "text/csv",
        },
        method: "PUT",
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    upload,
  };
};
