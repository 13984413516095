/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddOrgAdmins200Response,
  AddOrgAdminsRequest,
  AddOrgUUIDToCSV200Response,
  AddOrgUUIDToCSVRequest,
  CreateOrgs200Response,
  ExtendQespAdmins200Response,
  GetCSVUploadLink200Response,
  GetEligibleApps200Response,
  GetUnextendedQesps200Response,
  IssueVerifiedQespFoliosRequest,
  QespPortalError,
  QespPortalError1,
} from '../models/index';
import {
    AddOrgAdmins200ResponseFromJSON,
    AddOrgAdmins200ResponseToJSON,
    AddOrgAdminsRequestFromJSON,
    AddOrgAdminsRequestToJSON,
    AddOrgUUIDToCSV200ResponseFromJSON,
    AddOrgUUIDToCSV200ResponseToJSON,
    AddOrgUUIDToCSVRequestFromJSON,
    AddOrgUUIDToCSVRequestToJSON,
    CreateOrgs200ResponseFromJSON,
    CreateOrgs200ResponseToJSON,
    ExtendQespAdmins200ResponseFromJSON,
    ExtendQespAdmins200ResponseToJSON,
    GetCSVUploadLink200ResponseFromJSON,
    GetCSVUploadLink200ResponseToJSON,
    GetEligibleApps200ResponseFromJSON,
    GetEligibleApps200ResponseToJSON,
    GetUnextendedQesps200ResponseFromJSON,
    GetUnextendedQesps200ResponseToJSON,
    IssueVerifiedQespFoliosRequestFromJSON,
    IssueVerifiedQespFoliosRequestToJSON,
    QespPortalErrorFromJSON,
    QespPortalErrorToJSON,
    QespPortalError1FromJSON,
    QespPortalError1ToJSON,
} from '../models/index';

export interface AddOrgAdminsOperationRequest {
    requestBody: AddOrgAdminsRequest;
}

export interface AddOrgUUIDToCSVOperationRequest {
    filename: AddOrgUUIDToCSVRequest;
}

export interface CreateOrgsRequest {
    filename: AddOrgUUIDToCSVRequest;
}

export interface ExtendQespAdminsRequest {
    qespId: string;
    body: AddOrgUUIDToCSVRequest;
}

export interface GetCSVUploadLinkRequest {
    uploadType: string;
}

export interface IssueVerifiedQespFoliosOperationRequest {
    qesps: IssueVerifiedQespFoliosRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Add Org Admins
     */
    async addOrgAdminsRaw(requestParameters: AddOrgAdminsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddOrgAdmins200Response>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling addOrgAdmins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/add-org-admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrgAdminsRequestToJSON(requestParameters.requestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddOrgAdmins200ResponseFromJSON(jsonValue));
    }

    /**
     * Add Org Admins
     */
    async addOrgAdmins(requestParameters: AddOrgAdminsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddOrgAdmins200Response> {
        const response = await this.addOrgAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add orgUUID to csv
     */
    async addOrgUUIDToCSVRaw(requestParameters: AddOrgUUIDToCSVOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddOrgUUIDToCSV200Response>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling addOrgUUIDToCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/add-org-uuid-to-csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrgUUIDToCSVRequestToJSON(requestParameters.filename),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddOrgUUIDToCSV200ResponseFromJSON(jsonValue));
    }

    /**
     * Add orgUUID to csv
     */
    async addOrgUUIDToCSV(requestParameters: AddOrgUUIDToCSVOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddOrgUUIDToCSV200Response> {
        const response = await this.addOrgUUIDToCSVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create orgs
     */
    async createOrgsRaw(requestParameters: CreateOrgsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOrgs200Response>> {
        if (requestParameters.filename === null || requestParameters.filename === undefined) {
            throw new runtime.RequiredError('filename','Required parameter requestParameters.filename was null or undefined when calling createOrgs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/create-orgs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrgUUIDToCSVRequestToJSON(requestParameters.filename),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOrgs200ResponseFromJSON(jsonValue));
    }

    /**
     * Create orgs
     */
    async createOrgs(requestParameters: CreateOrgsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOrgs200Response> {
        const response = await this.createOrgsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Extend Qesp admins
     */
    async extendQespAdminsRaw(requestParameters: ExtendQespAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExtendQespAdmins200Response>> {
        if (requestParameters.qespId === null || requestParameters.qespId === undefined) {
            throw new runtime.RequiredError('qespId','Required parameter requestParameters.qespId was null or undefined when calling extendQespAdmins.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling extendQespAdmins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/extend-qesp-admins/{qespId}`.replace(`{${"qespId"}}`, encodeURIComponent(String(requestParameters.qespId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrgUUIDToCSVRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExtendQespAdmins200ResponseFromJSON(jsonValue));
    }

    /**
     * Extend Qesp admins
     */
    async extendQespAdmins(requestParameters: ExtendQespAdminsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExtendQespAdmins200Response> {
        const response = await this.extendQespAdminsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a link to upload csv file
     */
    async getCSVUploadLinkRaw(requestParameters: GetCSVUploadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCSVUploadLink200Response>> {
        if (requestParameters.uploadType === null || requestParameters.uploadType === undefined) {
            throw new runtime.RequiredError('uploadType','Required parameter requestParameters.uploadType was null or undefined when calling getCSVUploadLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.uploadType !== undefined) {
            queryParameters['uploadType'] = requestParameters.uploadType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/upload-link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCSVUploadLink200ResponseFromJSON(jsonValue));
    }

    /**
     * Get a link to upload csv file
     */
    async getCSVUploadLink(requestParameters: GetCSVUploadLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCSVUploadLink200Response> {
        const response = await this.getCSVUploadLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEligibleAppsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEligibleApps200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/eligible-apps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEligibleApps200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getEligibleApps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEligibleApps200Response> {
        const response = await this.getEligibleAppsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get all the service providers which haven\'t extended qesp access template.
     */
    async getUnextendedQespsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUnextendedQesps200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/unextended-qesps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUnextendedQesps200ResponseFromJSON(jsonValue));
    }

    /**
     * Get all the service providers which haven\'t extended qesp access template.
     */
    async getUnextendedQesps(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUnextendedQesps200Response> {
        const response = await this.getUnextendedQespsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Issue verified service provider folios to newly created orgs
     */
    async issueVerifiedQespFoliosRaw(requestParameters: IssueVerifiedQespFoliosOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.qesps === null || requestParameters.qesps === undefined) {
            throw new runtime.RequiredError('qesps','Required parameter requestParameters.qesps was null or undefined when calling issueVerifiedQespFolios.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/issue-verified-qesp-folios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IssueVerifiedQespFoliosRequestToJSON(requestParameters.qesps),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Issue verified service provider folios to newly created orgs
     */
    async issueVerifiedQespFolios(requestParameters: IssueVerifiedQespFoliosOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.issueVerifiedQespFoliosRaw(requestParameters, initOverrides);
    }

}
