// Copyright 2023 Merit International Inc. All Rights Reserved

import { AgentClient } from "../services/AgentClient";
import { useAlertStore } from "../stores/alertStore";
import { useAppStore } from "../stores/appStore";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import jwt_decode from "jwt-decode";
import type { MeritUserInfo } from "../types/user";
import type { UseMeritAuth0 } from "../types/auth";

const useLogin = () => {
  const { loginWithPopup } = useAuth0();
  const { deleteAlert, setAlert } = useAlertStore();

  const showToast = (message: string) => {
    setAlert({
      closable: true,
      id: uuidv4(),
      onPressDelete: id => {
        deleteAlert(id);
      },
      size: "medium",
      text: message,
      type: "error",
    });
  };

  const doPkceFlow = async () => {
    try {
      await loginWithPopup({
        authorizationParams: { prompt: "login", scope: "openid profile offline_access" },
      });
    } catch {
      showToast("There was a problem logging in");
    }
  };

  return doPkceFlow;
};

const useLogout = () => {
  const { logout: logoutAuth0 } = useAuth0();

  const logout = () => {
    logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
  };

  return logout;
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated, isLoading, user } =
    useAuth0<MeritUserInfo>();
  const [accessToken, setAccessToken] = useState<string>();
  const [idToken, setIdToken] = useState<string>();
  const { isToSAccepted, setIsToSAccepted } = useAppStore();

  useEffect(() => {
    const setTokens = async () => {
      // eslint-disable-next-line no-underscore-dangle
      const theIdToken = (await getIdTokenClaims())?.__raw;
      const theAccessToken = await getAccessTokenSilently();
      setAccessToken(theAccessToken);
      setIdToken(theIdToken);
    };

    const setToSState = async () => {
      if (accessToken !== undefined && accessToken !== "") {
        const { agentID } = jwt_decode<MeritUserInfo>(accessToken);
        const agentClient = AgentClient(accessToken);
        const { capabilitiesApproved, tosAccepted } = await agentClient.getLinks(agentID);

        if (capabilitiesApproved && tosAccepted) {
          setIsToSAccepted(true);
        }
      }
    };

    if (isAuthenticated) {
      setTokens();
      setToSState();
    }
  }, [
    accessToken,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isToSAccepted,
    setIsToSAccepted,
  ]);

  return {
    accessToken: accessToken ?? "",
    idToken: idToken ?? "",
    isAuthenticated,
    isLoading,
    user: user ?? null,
  };
};

export { useLogin, useLogout, useMeritAuth0 };
