import { create } from "zustand";
import { persist } from "zustand/middleware";

export type AppState = {
  readonly selectedApp: string | null;
  readonly setSelectedApp: (appName: string | null) => void;
  readonly isToSAccepted: boolean;
  readonly setIsToSAccepted: (tosState: boolean) => void;
};

export const useAppStore = create<AppState>()(
  persist(
    set => ({
      isToSAccepted: false,
      selectedApp: null,
      setIsToSAccepted: (toSState: boolean) => {
        set(() => ({ isToSAccepted: toSState }));
      },
      setSelectedApp: (appName: string | null) => {
        set(() => ({ selectedApp: appName }));
      },
    }),
    {
      getStorage: () => localStorage,
      name: "qesp-app-storage",
    }
  )
);
