import { Body, Heading, useTheme } from "@merit/frontend-components";
import { type ExtendQespAdmins200ResponseExtendedQespDetailsInner, ResponseError } from "../../gen";
import { Helpers } from "@merit/frontend-utils";
import {
  Paper,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Spin } from "../Spin";
import { VerticalSpacer } from "../Spacer";
import { useAlertStore } from "../../stores/alertStore";
import { useApi } from "../../api/api";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import { useUpload } from "../../utils/useUpload";
import { v4 as uuidv4 } from "uuid";
import React, { useEffect, useState } from "react";

const { None, Some } = Helpers;

type Props = {
  readonly orgId: string;
  readonly orgName: string;
  readonly fileName: string;
};

type StepSummary = {
  readonly message: string;
  readonly data?: readonly ExtendQespAdmins200ResponseExtendedQespDetailsInner[];
  readonly error?: boolean;
};

const steps = ["Get csv upload link", "Upload CSV", "Extend QESP"];

export const ExtendQespStatusModal = ({ fileName, orgId, orgName }: Props) => {
  const { theme } = useTheme();
  const { errorHandler } = useServerErrorHandler();
  const { accessToken, api, idToken } = useApi();
  const [stepSummary, setStepSummary] = useState<readonly StepSummary[]>([]);
  const [activeStep, setActiveStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const csvUpload = useUpload();
  const { deleteAlert, setAlert } = useAlertStore();

  const styles = StyleSheet.create({
    buttonsContainer: {
      ...(None(fileName) && { alignSelf: "flex-end" }),
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      borderColor: theme.colors.border.subdued,
      borderWidth: 1,
      marginHorizontal: 10,
      marginVertical: 10,
      minHeight: 450,
      minWidth: 768,
    },
    summaryContainer: {
      flexDirection: "row",
    },
  });

  const extendQespAdmin = async () => {
    try {
      setLoading(true);
      const { filename, link } = await api.getCSVUploadLink({ uploadType: "qesp" });
      setActiveStep(0);
      setStepSummary(prevState => [
        ...prevState,
        {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          message: `Received upload link with filename: ${filename}`,
        },
      ]);
      await csvUpload.upload(link, fileName);
      setActiveStep(1);
      setStepSummary(prevState => [
        ...prevState,
        {
          message: `Successfully uploaded the csv file`,
        },
      ]);
      const { extendedQespDetails } = await api.extendQespAdmins({
        body: { filename },
        qespId: orgId,
      });
      setActiveStep(2);

      if (None(extendedQespDetails)) {
        throw new Error("Somehow extend QESP response is null");
      }

      setStepSummary(prevState => [
        ...prevState,
        {
          data: extendedQespDetails,
          message: `QESP details`,
        },
      ]);

      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        text: `Qesp admin added successfully.`,
        type: "success",
      });
    } catch (error) {
      if (error instanceof ResponseError) {
        const err = await error.response.json();

        setStepSummary(prevState => [
          ...prevState,
          {
            data: undefined,
            error: true,
            message: err.message ?? "Something went wrong",
          },
        ]);

        return;
      }

      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Some(accessToken) && idToken !== "") {
      extendQespAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <>
      <View style={styles.container}>
        <View style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
          <View>
            <View style={{ flexDirection: "row" }}>
              <Heading bold level="5">
                Org Name:
              </Heading>
              <Body> {orgName} </Body>

              <VerticalSpacer />
            </View>
            <View style={{ flexDirection: "row" }}>
              <Heading bold level="5">
                Org Id:
              </Heading>
              <Body> {orgId} </Body>
            </View>
          </View>
          <VerticalSpacer />

          <VerticalSpacer />

          <View>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>
                    <Heading bold level="5">
                      {label}
                    </Heading>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </View>

          <VerticalSpacer />

          <View>
            {stepSummary.length > 0 && (
              <View style={styles.summaryContainer}>
                <Heading bold level="5">
                  Summary of step-1:
                </Heading>

                <Body>
                  <Text
                    style={
                      Some(stepSummary[0].error) && stepSummary[0].error
                        ? { color: "red" }
                        : undefined
                    }
                  >
                    {" "}
                    {stepSummary[0].message}
                  </Text>
                </Body>
              </View>
            )}
            <VerticalSpacer />
            {stepSummary.length > 1 && (
              <View style={styles.summaryContainer}>
                <Heading bold level="5">
                  Summary of step-2:
                </Heading>

                <Body>
                  <Text
                    style={
                      Some(stepSummary[1].error) && stepSummary[1].error
                        ? { color: "red" }
                        : undefined
                    }
                  >
                    {" "}
                    {stepSummary[1].message}
                  </Text>
                </Body>
              </View>
            )}

            <VerticalSpacer />
            {stepSummary.length > 2 && (
              <View style={styles.summaryContainer}>
                <Heading bold level="5">
                  Summary of step-3:
                </Heading>

                <Body>
                  <Text
                    style={
                      Some(stepSummary[2].error) && stepSummary[2].error
                        ? { color: "red" }
                        : undefined
                    }
                  >
                    {" "}
                    {stepSummary[2].message}
                  </Text>
                </Body>
              </View>
            )}
            <VerticalSpacer />

            {Some(stepSummary[2]) &&
              Some(stepSummary[2].data) &&
              stepSummary[2].data.length > 0 && (
                <Body>
                  <ScrollView>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Heading bold level="5">
                                Status
                              </Heading>
                            </TableCell>
                            <TableCell align="right">
                              <Heading bold level="5">
                                Container ID
                              </Heading>
                            </TableCell>
                            <TableCell align="right">
                              <Heading bold level="5">
                                Email
                              </Heading>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stepSummary[2].data.map(row => (
                            <TableRow
                              key={row.containerId}
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.authorizationStatus}
                              </TableCell>
                              <TableCell align="right">{row.containerId}</TableCell>
                              <TableCell align="right">{row.email}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ScrollView>
                </Body>
              )}
          </View>

          <View>{loading && <Spin />}</View>
        </View>
      </View>

      <VerticalSpacer />
    </>
  );
};
