import { EllipsisText } from "../EllipsisText/EllipsisText";
import { StyleSheet, View } from "react-native";
import { getColumnWidthStyle } from "./utils";
import { useTheme } from "@merit/frontend-components";
import React from "react";
import type { DatagridColumn } from "./types";

type ObjectWithError = object & {
  readonly hasError?: boolean;
};

export type DatagridBodyProps<T extends ObjectWithError> = {
  readonly data: readonly T[];
  readonly columns: readonly DatagridColumn<T>[];
  readonly noDataText?: string;
  readonly getItemKey?: (item: T) => string;
};

export function DatagridBody<T extends ObjectWithError>({
  columns,
  data,
  getItemKey,
  noDataText,
}: DatagridBodyProps<T>) {
  const { theme } = useTheme();

  const styles = StyleSheet.create({
    cell: {
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "flex-start",
      padding: theme.spacing.l,
    },
    row: {
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      flexDirection: "row",
      paddingHorizontal: theme.spacing.l,
    },
    rowWithError: {
      backgroundColor: "rgba(255, 237, 239, 1)", // theme.colors.action.critical.default is wrong in theme
    },
  });

  const itemKeyFunc = getItemKey === undefined ? (item: T) => JSON.stringify(item) : getItemKey;
  if (data.length === 0) {
    return (
      <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
        {noDataText ?? "No data available"}
      </View>
    );
  }

  return (
    <>
      {data.map(item => (
        <View
          key={itemKeyFunc(item)}
          style={[styles.row, item.hasError === true && styles.rowWithError]}
        >
          {columns.map(column => (
            <View key={String(column.key)} style={[styles.cell, getColumnWidthStyle(column.size)]}>
              {column.renderer === undefined ? (
                <EllipsisText
                  capitalize={column.capitalize}
                  text={item[column.key as keyof T] as string}
                />
              ) : (
                column.renderer(item)
              )}
            </View>
          ))}
        </View>
      ))}
    </>
  );
}
