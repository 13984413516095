// Copyright 2023 Merit International Inc. All Rights Reserved

import "./Popover.css";
import { View } from "react-native";
import { usePopper } from "react-popper";
import { useTheme } from "@merit/frontend-components";
import React, { useState } from "react";
import type { Placement } from "@popperjs/core";
import type { ReactNode } from "react";

export type PopoverProps = {
  readonly children?: ReactNode;
  readonly originElement?: HTMLDivElement;
  readonly placement?: Placement;
  readonly showArrow?: boolean;
  readonly testID?: string;
  readonly testUniqueID?: string;
};

export const Popover = ({
  children,
  originElement,
  placement = "auto",
  showArrow = false,
}: PopoverProps) => {
  const { theme } = useTheme();
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>();

  const modifiers = showArrow
    ? [
        { name: "arrow", options: { element: arrowElement } },
        {
          name: "offset",
          options: {
            offset: [0, 12],
          },
        },
      ]
    : [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ];

  const { attributes, styles: popperStyles } = usePopper(originElement, popperElement, {
    modifiers,
    placement,
  });

  return (
    <div
      id="tooltip"
      ref={setPopperElement}
      role="tooltip"
      {...attributes.popper}
      style={{
        ...popperStyles.popper,
        ...theme.elevations.depth4,
        backgroundColor: theme.colors.surface.default,
        border: "1px solid rgba(0, 0, 0, 0.15)",
        borderRadius: theme.borderRadii.s,
        zIndex: 100,
      }}
    >
      <View>{children}</View>
      {showArrow && (
        <div
          data-popper-arrow
          id="arrow"
          ref={setArrowElement}
          style={{
            ...popperStyles.arrow,
          }}
        />
      )}
    </div>
  );
};
