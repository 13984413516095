/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendQespAdmins200ResponseExtendedQespDetailsInner
 */
export interface ExtendQespAdmins200ResponseExtendedQespDetailsInner {
    /**
     * 
     * @type {string}
     * @memberof ExtendQespAdmins200ResponseExtendedQespDetailsInner
     */
    containerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendQespAdmins200ResponseExtendedQespDetailsInner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendQespAdmins200ResponseExtendedQespDetailsInner
     */
    authorizationStatus?: ExtendQespAdmins200ResponseExtendedQespDetailsInnerAuthorizationStatusEnum;
}


/**
 * @export
 */
export const ExtendQespAdmins200ResponseExtendedQespDetailsInnerAuthorizationStatusEnum = {
    Success: 'success',
    Fail: 'fail'
} as const;
export type ExtendQespAdmins200ResponseExtendedQespDetailsInnerAuthorizationStatusEnum = typeof ExtendQespAdmins200ResponseExtendedQespDetailsInnerAuthorizationStatusEnum[keyof typeof ExtendQespAdmins200ResponseExtendedQespDetailsInnerAuthorizationStatusEnum];


/**
 * Check if a given object implements the ExtendQespAdmins200ResponseExtendedQespDetailsInner interface.
 */
export function instanceOfExtendQespAdmins200ResponseExtendedQespDetailsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSON(json: any): ExtendQespAdmins200ResponseExtendedQespDetailsInner {
    return ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSONTyped(json, false);
}

export function ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendQespAdmins200ResponseExtendedQespDetailsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerId': !exists(json, 'containerId') ? undefined : json['containerId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'authorizationStatus': !exists(json, 'authorizationStatus') ? undefined : json['authorizationStatus'],
    };
}

export function ExtendQespAdmins200ResponseExtendedQespDetailsInnerToJSON(value?: ExtendQespAdmins200ResponseExtendedQespDetailsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerId': value.containerId,
        'email': value.email,
        'authorizationStatus': value.authorizationStatus,
    };
}

