import * as ExpoDocumentPicker from "expo-document-picker";
import { Body, Button, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../Spacer";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "../../utils/Image";
import React, { useState } from "react";

const { None, Some } = Helpers;

type Props = {
  readonly onSave: (file: ExpoDocumentPicker.DocumentPickerResult) => void;
  readonly onCancel: () => void;
  readonly fileTypes: readonly string[];
  readonly validFileTypes: readonly string[];
  readonly fileTypeName: string; // e.g. image, csv
  readonly recommendationText?: string;
};

export const UploadFileModal = ({
  fileTypeName,
  fileTypes,
  onCancel,
  onSave,
  recommendationText,
  validFileTypes,
}: Props) => {
  const { theme } = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [uri, setUri] = useState<ExpoDocumentPicker.DocumentPickerResult>();

  const pickDocument = async () => {
    setError(undefined);

    const result = await ExpoDocumentPicker.getDocumentAsync({
      copyToCacheDirectory: false,
      multiple: false,
      // @ts-expect-error Filetypes does not actually need to be mutable here so ignore it.
      type: fileTypes,
    });

    if (Boolean(result.canceled)) {
      return;
    }

    if (
      Some(result) &&
      Some(result.assets) &&
      Some(result.assets[0]) &&
      Boolean(result.assets[0].mimeType)
    ) {
      const mimeType = result.assets[0].mimeType;
      if (Some(mimeType) && !validFileTypes.includes(mimeType)) {
        setError(`Selected file type not supported`);
      }

      setFileName(result.assets[0].name);
      setUri(result);
    }
  };

  const styles = StyleSheet.create({
    buttonsContainer: {
      ...(None(fileName) && { alignSelf: "flex-end" }),
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      alignItems: "center",
      borderColor: theme.colors.border.subdued,
      borderWidth: 1,
      justifyContent: "center",
      minHeight: 200,
      minWidth: 456,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={{ paddingVertical: 38 }}>
          <VerticalSpacer size={theme.spacing.s} />
          {Some(fileName) ? (
            <View style={{ alignItems: "center" }}>
              <Image accessible source={Images.uploadSuccess} style={{ height: 48, width: 48 }} />
              <VerticalSpacer size={theme.spacing.xxl} />
              <Body style={{ color: theme.colors.text.subdued }}>File name: {fileName}</Body>
            </View>
          ) : (
            <>
              <View style={{ alignItems: "center" }}>
                <Pressable
                  onPress={() => {
                    pickDocument();
                  }}
                >
                  <Image accessible source={Images.selectFile} style={{ height: 48, width: 48 }} />
                </Pressable>
              </View>
              <VerticalSpacer size={theme.spacing.xxl} />
              <View style={{ flexDirection: "row", justifyContent: "center" }}>
                <Body>Drop your file(s) here or</Body>
                <HorizontalSpacer size={theme.spacing.xs} />
                <Pressable
                  onPress={() => {
                    setFileName(undefined);
                    pickDocument();
                  }}
                >
                  <Body color={theme.colors.brand.oceanBlue}>browse</Body>
                </Pressable>
              </View>
              <VerticalSpacer size={theme.spacing.s} />
              <Body style={{ color: theme.colors.text.subdued }}>{recommendationText}</Body>
            </>
          )}
        </View>
      </View>

      <VerticalSpacer />

      <View style={styles.buttonsContainer}>
        {Some(fileName) && (
          <Pressable
            onPress={() => {
              setFileName(undefined);
              pickDocument();
            }}
          >
            <Body style={{ color: theme.colors.interactive.hovered }}>
              Choose new {fileTypeName}
            </Body>
          </Pressable>
        )}

        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Button
            onPress={() => {
              onCancel();
            }}
            text="Cancel"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            onPress={() => {
              if (Some(fileName) && Some(uri)) {
                onSave(uri);
              }
            }}
            text="Save"
            type="primary"
          />
        </View>
      </View>
    </>
  );
};
