/* eslint-disable @typescript-eslint/no-require-imports */

import * as Sentry from "sentry-expo";
import { AuthProvider } from "./components/AuthProvider";
import { Navigator } from "./Router";
import { SentryErrorBoundary } from "./utils/SentryErrorBoundary";
import { View } from "react-native";
import { config } from "../src/config/config";
import { useFonts } from "expo-font";
import React from "react";

const App = () => {
  const [loadedFonts] = useFonts({
    ProximaNova: require("./assets/fonts/ProximaNova.otf"),
    ProximaNovaBold: require("./assets/fonts/ProximaNovaBold.otf"),
    ProximaNovaMedium: require("./assets/fonts/ProximaNovaMedium.otf"),
    ProximaNovaSemiBold: require("./assets/fonts/ProximaNovaSemiBold.otf"),
  });

  Sentry.init({
    ...config.sentry,
    debug: config.sentry.environment === "dev",
    enableInExpoDevelopment: true,
    enabled: config.sentry.environment !== "dev",
  });

  if (loadedFonts) {
    return (
      <SentryErrorBoundary>
        <AuthProvider>
          <Navigator />
        </AuthProvider>
      </SentryErrorBoundary>
    );
  }

  return <View />;
};

export { App };
