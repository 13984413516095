/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtendQespAdmins200ResponseExtendedQespDetailsInner } from './ExtendQespAdmins200ResponseExtendedQespDetailsInner';
import {
    ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSON,
    ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSONTyped,
    ExtendQespAdmins200ResponseExtendedQespDetailsInnerToJSON,
} from './ExtendQespAdmins200ResponseExtendedQespDetailsInner';

/**
 * 
 * @export
 * @interface ExtendQespAdmins200Response
 */
export interface ExtendQespAdmins200Response {
    /**
     * 
     * @type {Array<ExtendQespAdmins200ResponseExtendedQespDetailsInner>}
     * @memberof ExtendQespAdmins200Response
     */
    extendedQespDetails: Array<ExtendQespAdmins200ResponseExtendedQespDetailsInner> | null;
}

/**
 * Check if a given object implements the ExtendQespAdmins200Response interface.
 */
export function instanceOfExtendQespAdmins200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "extendedQespDetails" in value;

    return isInstance;
}

export function ExtendQespAdmins200ResponseFromJSON(json: any): ExtendQespAdmins200Response {
    return ExtendQespAdmins200ResponseFromJSONTyped(json, false);
}

export function ExtendQespAdmins200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendQespAdmins200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extendedQespDetails': (json['extendedQespDetails'] === null ? null : (json['extendedQespDetails'] as Array<any>).map(ExtendQespAdmins200ResponseExtendedQespDetailsInnerFromJSON)),
    };
}

export function ExtendQespAdmins200ResponseToJSON(value?: ExtendQespAdmins200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extendedQespDetails': (value.extendedQespDetails === null ? null : (value.extendedQespDetails as Array<any>).map(ExtendQespAdmins200ResponseExtendedQespDetailsInnerToJSON)),
    };
}

