// Copyright 2023 Merit International Inc. All Rights Reserved

import { Configuration, DefaultConfig } from "../gen";
import { DefaultApi } from "../gen/apis/DefaultApi";
import { config as appConfig } from "../config/config";
import { useMemo } from "react";
import { useMeritAuth0 } from "../hooks/auth";
import type { ConfigurationParameters, ResponseContext } from "../gen";

const BASE_API_CONFIG = {
  basePath: appConfig.api.baseUrl,
  headers: {},
};

export const useApi = () => {
  const { accessToken, idToken } = useMeritAuth0();

  return useMemo(() => {
    const checkForUnauthorized = async (context: ResponseContext): Promise<Response> => {
      const p = await new Promise<Response>((resolve, reject) => {
        if (context.response.status === 401) {
          reject(Error("401 Unauthorized"));
        } else {
          resolve(context.response);
        }
      });

      return p;
    };
    const isUnauthorized = accessToken === "" && idToken === "";

    const config: ConfigurationParameters = {
      ...DefaultConfig,
      basePath: BASE_API_CONFIG.basePath,
      headers: isUnauthorized
        ? BASE_API_CONFIG.headers
        : {
            ...BASE_API_CONFIG.headers,
            Authorization: `Bearer ${accessToken}`,
            "x-id-token": idToken,
          },
      middleware: [isUnauthorized ? {} : { post: checkForUnauthorized }],
    };

    const api = new DefaultApi(new Configuration(config));

    return { accessToken, api, idToken };
  }, [accessToken, idToken]);
};
