import { Helpers } from "@merit/frontend-utils";
import { Linking, Pressable, Text } from "react-native";
import type { StyleProp, TextStyle } from "react-native";

type Props = {
  readonly url: string;
  readonly text: string;
  readonly onPress?: () => void;
  readonly textStyle?: StyleProp<TextStyle>;
};

export const ExternalLink = ({ onPress, text, textStyle, url }: Props) => (
  <Pressable
    onPress={() => {
      Linking.openURL(url);

      if (Helpers.Some(onPress)) {
        onPress();
      }
    }}
  >
    <Text style={[{ color: "#006699" }, textStyle]}>{text}</Text>
  </Pressable>
);
