// Copyright 2022 Merit International Inc. All Rights Reserved

import * as Sentry from "sentry-expo";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorScreen } from "../screens/ErrorScreen/ErrorScreen";
import type { ReactNode } from "react";

type Props = {
  readonly children: ReactNode;
};

export function SentryErrorBoundary({ children }: Props) {
  return (
    <ErrorBoundary
      fallbackRender={ErrorScreen}
      onError={error => {
        Sentry.Browser.captureException(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
