import { Body, Button, Heading, MeritLogo, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import { VerticalSpacer } from "../../components/Spacer";
import { useLogout } from "../../hooks/auth";
import React from "react";

export const NotEligibleScreen = () => {
  const { theme } = useTheme();
  const logout = useLogout();

  const styles = StyleSheet.create({
    centerContent: { alignItems: "center", justifyContent: "center" },
  });

  return (
    <>
      <View style={[styles.centerContent, { padding: 48 }]}>
        <MeritLogo />
      </View>
      <View style={[styles.centerContent, { flex: 1 }]}>
        <Heading level="1" style={{ fontSize: 42 }}>
          Not Eligible
        </Heading>
        <VerticalSpacer size={theme.spacing.xxl} />
        <Body center color={theme.colors.action.default}>
          You are not eligible to access this portal. Please contact administrator
        </Body>
        <VerticalSpacer size={61} />
        <Button
          iconLeft="logoutMediumDefault"
          onPress={() => {
            logout();
          }}
          size="small"
          text="Logout"
          type="primary"
        />
      </View>
    </>
  );
};
