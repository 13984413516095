import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "../../config/config";
import type { FC, PropsWithChildren } from "react";

export type FCWithChildren<P = unknown> = FC<PropsWithChildren<P>>;

const AuthProvider: FCWithChildren = ({ children }) => {
  const { auth0Domain, clientId } = config.auth;

  return (
    <Auth0Provider
      authorizationParams={{ audience: config.api.issuance.baseUrl }}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={`https://${auth0Domain}`}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
