/* tslint:disable */
/* eslint-disable */
/**
 * esa-qesp-service
 * Documentation of a sample api used to bootstrap microservices in the Merit ecosystem.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrgs200ResponseOrgDetailsInner
 */
export interface CreateOrgs200ResponseOrgDetailsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    containerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    orgUUID?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    orgName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    authorizationStatus?: CreateOrgs200ResponseOrgDetailsInnerAuthorizationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateOrgs200ResponseOrgDetailsInner
     */
    error?: string;
}


/**
 * @export
 */
export const CreateOrgs200ResponseOrgDetailsInnerAuthorizationStatusEnum = {
    Success: 'success',
    Fail: 'fail'
} as const;
export type CreateOrgs200ResponseOrgDetailsInnerAuthorizationStatusEnum = typeof CreateOrgs200ResponseOrgDetailsInnerAuthorizationStatusEnum[keyof typeof CreateOrgs200ResponseOrgDetailsInnerAuthorizationStatusEnum];


/**
 * Check if a given object implements the CreateOrgs200ResponseOrgDetailsInner interface.
 */
export function instanceOfCreateOrgs200ResponseOrgDetailsInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateOrgs200ResponseOrgDetailsInnerFromJSON(json: any): CreateOrgs200ResponseOrgDetailsInner {
    return CreateOrgs200ResponseOrgDetailsInnerFromJSONTyped(json, false);
}

export function CreateOrgs200ResponseOrgDetailsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOrgs200ResponseOrgDetailsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containerId': !exists(json, 'containerId') ? undefined : json['containerId'],
        'orgUUID': !exists(json, 'orgUUID') ? undefined : json['orgUUID'],
        'orgName': !exists(json, 'orgName') ? undefined : json['orgName'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'authorizationStatus': !exists(json, 'authorizationStatus') ? undefined : json['authorizationStatus'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function CreateOrgs200ResponseOrgDetailsInnerToJSON(value?: CreateOrgs200ResponseOrgDetailsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containerId': value.containerId,
        'orgUUID': value.orgUUID,
        'orgName': value.orgName,
        'orgId': value.orgId,
        'authorizationStatus': value.authorizationStatus,
        'error': value.error,
    };
}

